import { environment } from '../../../environments/environment';
import { Deletable } from '../interface/deletable';
import { Identifiable } from '../interface/identifiable';
import { Factory } from '../interface/factory';
import { I18nString } from './i18nstring';
import { BookableSettings } from './bookable-settings';

export enum TenantUnitType {
  Voz = 'Voz',
  Heimstaden = 'Heimstaden',
  Serneke = 'Serneke',
  Granitor = 'Granitor',
  GranitorProperties = 'GranitorProperties',
  Karlatornet = 'Karlatornet',
  EasyDepot = 'EasyDepot',
  Revelop = 'Revelop',
  VozWork = 'VozWork',
}

export class VisualProfile {
  darkMode: VisualProfileDarkMode;
  lightMode: VisualProfileLightMode;
  constructor(json: any) {
    this.darkMode = new VisualProfileDarkMode(json.darkMode);
    this.lightMode = new VisualProfileLightMode(json.lightMode);
  }
}

export class VisualProfileDarkMode {
  icon: string;
  logo: string;
  primaryColor: string;
  constructor(json: any) {
    this.icon = json.icon;
    this.logo = json.logo;
    this.primaryColor = json.primaryColor;
  }
}
export class VisualProfileLightMode {
  icon: string;
  logo: string;
  primaryColor: string;
  constructor(json: any) {
    this.icon = json.icon;
    this.logo = json.logo;
    this.primaryColor = json.primaryColor;
  }
}

export class ContactPerson {
  name: string;
  phone: string;
  roles: I18nString[];
  email?: string;

  constructor(json: any) {
    this.name = json.name;
    this.phone = json.phone;
    this.roles = json.roles ? json.roles.map((json: any) => new I18nString(json)) : [];
    this.email = json.email ? json.email : undefined;
  }
}

export class SystemUser {
  name: string;
  image?: string;

  constructor(json: any) {
    this.name = json.name;
    this.image = json.image ? json.image : undefined;
  }

  getImageUrl(): string | undefined {
    return this.image ? environment.blobUrl + '/images/' + this.image : undefined;
  }
}

export class FaultReportLink {
  id: string;
  titles: I18nString[];
  url: string;
  constructor(json: any) {
    this.id = json.id;
    this.titles = json.titles ? json.titles.map((json: any) => new I18nString(json)) : [];
    this.url = json.url;
  }
}

export class FaultReportPhone {
  id: string;
  titles: I18nString[];
  phone: string;
  constructor(json: any) {
    this.id = json.id;
    this.titles = json.titles ? json.titles.map((json: any) => new I18nString(json)) : [];
    this.phone = json.phone;
  }
}

export class FaultReport {
  titles: I18nString[];
  texts: I18nString[];
  links: FaultReportLink[];
  phones: FaultReportPhone[];
  email: string;
  constructor(json: any) {
    this.titles = json.titles ? json.titles.map((json: any) => new I18nString(json)) : [];
    this.texts = json.texts ? json.texts.map((json: any) => new I18nString(json)) : [];
    this.links = json.links ? json.links.map((json: any) => new FaultReportLink(json)) : [];
    this.phones = json.phones ? json.phones.map((json: any) => new FaultReportPhone(json)) : [];
    this.email = json.email ? json.email : [];
  }
}

export class TenantUnit implements Deletable, Identifiable {
  id: string;
  deleted: boolean;

  type: TenantUnitType;

  names: I18nString[];
  intros: I18nString[];
  abouts: I18nString[];
  images: string[];

  srenityCustomer?: string;

  contactPerson?: ContactPerson;
  emergencyContact?: ContactPerson;
  systemUser?: SystemUser;

  faultReport?: FaultReport;
  visualProfile?: VisualProfile;

  bookableSettings: BookableSettings;
  timezone: string; // Europe/Stockholm

  created: Date;
  modified: Date;

  constructor(json: any) {
    this.id = json.id;
    this.deleted = json.deleted ? Boolean(json.deleted) : false;

    this.type = json.type as TenantUnitType;

    this.names = json.names ? json.names.map((json: any) => new I18nString(json)) : [];
    this.intros = json.intros ? json.intros.map((json: any) => new I18nString(json)) : [];
    this.abouts = json.abouts ? json.abouts.map((json: any) => new I18nString(json)) : [];
    this.images = json.images ? json.images : [];

    this.srenityCustomer = json.srenityCustomer ? json.srenityCustomer : undefined;

    this.contactPerson = json.contactPerson ? new ContactPerson(json.contactPerson) : undefined;
    this.emergencyContact = json.emergencyContact ? new ContactPerson(json.emergencyContact) : undefined;
    this.systemUser = json.systemUser ? new SystemUser(json.systemUser) : undefined;

    this.faultReport = json.faultReport ? new FaultReport(json.faultReport) : undefined;
    this.visualProfile = json.visualProfile ? new VisualProfile(json.visualProfile) : undefined;

    this.bookableSettings = new BookableSettings(json.bookableSettings);
    this.timezone = json.timezone;

    this.created = json.created;
    this.modified = json.modified;
  }

  hasOfficeType(): boolean {
    return (
      this.type == TenantUnitType.EasyDepot ||
      this.type == TenantUnitType.GranitorProperties ||
      this.type == TenantUnitType.Revelop ||
      this.type == TenantUnitType.VozWork
    );
  }

  hasLivingType(): boolean {
    return !this.hasOfficeType();
  }

  hasInviteUi() {
    return this.type !== TenantUnitType.Heimstaden;
  }

  hasFaultReporting() {
    return (
      this.type == TenantUnitType.Granitor ||
      this.type == TenantUnitType.GranitorProperties ||
      this.type == TenantUnitType.VozWork
    );
  }

  hasExternalFaultReporting() {
    return (
      this.type == TenantUnitType.Serneke ||
      this.type == TenantUnitType.Karlatornet ||
      this.type == TenantUnitType.Revelop
    );
  }

  hasEvents() {
    return true;
  }

  hasChatGroups() {
    return (
      this.hasLivingType() ||
      this.type === TenantUnitType.VozWork ||
      this.type === TenantUnitType.GranitorProperties ||
      this.type === TenantUnitType.EasyDepot
    );
  }

  hasDigitalSignages() {
    return (
      this.type !== TenantUnitType.EasyDepot &&
      this.type !== TenantUnitType.Karlatornet &&
      this.type !== TenantUnitType.Voz &&
      this.type !== TenantUnitType.Serneke
    );
  }

  static getFactory(): Factory<TenantUnit> {
    return new (class implements Factory<TenantUnit> {
      make(json: any): TenantUnit {
        const allowedTypes = Object.values(TenantUnitType);
        if (allowedTypes.includes(json.type)) {
          return new TenantUnit(json);
        } else {
          throw new Error('Unrecognized TenantUnit type (' + json.type + ').');
        }
      }

      getTableName(): string {
        return 'tenant_units';
      }
    })();
  }

  static getUrl(): string;
  static getUrl(tenantUnitId: string): string;
  static getUrl(tenantUnitId?: string): string {
    return '/tenant_units' + (tenantUnitId ? '/' + tenantUnitId : '');
  }

  getImageUrl(index: number = 0): string | undefined {
    return this.images[index] ? environment.blobUrl + '/images/' + this.images[index] : undefined;
  }

  getIconFileName(): string {
    switch (this.type) {
      case TenantUnitType.Serneke:
        return 'tu-icon-serneke.svg';
      case TenantUnitType.Granitor:
        return 'tu-icon-granitor.svg';
      case TenantUnitType.GranitorProperties:
        return 'tu-icon-granitor.svg';
      case TenantUnitType.Heimstaden:
        return 'tu-icon-heimstaden.svg';
      case TenantUnitType.Karlatornet:
        return 'tu-icon-karlatornet.svg';
      case TenantUnitType.EasyDepot:
        return 'tu-icon-easydepot.svg';
      case TenantUnitType.Revelop:
        return 'tu-icon-revelop.svg';
      default:
        return 'tu-icon.svg';
    }
  }
}
